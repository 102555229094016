import { appConfig } from './config.constants';

export const apiRoutes = {
    ROUTE_LOGIN: appConfig.API_ENDPOINT + '/login',
    ROUTE_EXISTEMAIL: appConfig.API_ENDPOINT + '/registroPadre/existenciaEmail',
    ROUTE_REGISTER: appConfig.API_ENDPOINT + '/registroPadre',
    ROUTE_FORGOTPASSWORD: appConfig.API_ENDPOINT + '/cuentaPadre/olvidoContrasena',
    ROUTE_VERIFYTOKEN_FP: appConfig.API_ENDPOINT + '/cuentaPadre/aceptaRestablecerContrasena',
    ROUTE_UPDATE_PASSWORD: appConfig.API_ENDPOINT + '/cuentaPadre/actualizarContrasena',
    ROUTE_RESET_PASSWORD: appConfig.API_ENDPOINT + '/login/changePassword',
    ROUTE_SERVICIO: appConfig.API_ENDPOINT + '/servicio',
    ROUTE_DASHBOARD: appConfig.API_ENDPOINT + '/dashboard',
    ROUTE_REGISTRO_CAJA: appConfig.API_ENDPOINT + '/registroCaja',
    ROUTE_CAJA_HISTORIAL: appConfig.API_ENDPOINT + '/cobro/historial',
    ROUTE_CICLO_OPERACION: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/infoByRol/ciclos',
    ROUTE_GRADO_CARRERA_SECCION_CICLOS: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/info/ciclos',
    ROUTE_GRUPO_UNIDAD_GCSC: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/grupoUnidad',
    ROUTE_GRUPO_UNIDAD_CICLO: appConfig.API_ENDPOINT + '/grupoUnidadCiclo',
    ROUTE_PERFIL_SECCION_CICLO: appConfig.API_ENDPOINT + '/perfilSeccionCiclo',
    ROUTE_SERVICIO_GCSCPM: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCicloMateriaProfesor',
    ROUTE_SERVICIO_GCSCPM_CODIGOSIRE: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCicloMateriaProfesor/codigoSire',
    ROUTE_PADRE: appConfig.API_ENDPOINT + '/perfilPadre',
    ROUTE_PERFIL_PADRE: appConfig.API_ENDPOINT + '/perfilPadre/info',
    ROUTE_GRUPO_UNIDAD_CICLO_DISPONIBLE: appConfig.API_ENDPOINT + '/grupoUnidadCiclo/info/create',
    ROUTE_GRADO_CARRERA_SECCION_CICLO: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo',
    ROUTE_GRADO_CARRERA_SECCION_CICLO_ESTUDIANTE: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCicloEstudiante',
    ROUTE_GCSCE_INSCRIBIR: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCicloEstudiante/infoEstudiante/inscribir',
    ROUTE_DESCUENTO: appConfig.API_ENDPOINT + '/descuento',
    ROUTE_SECCION: appConfig.API_ENDPOINT + '/seccion',
    ROUTE_GRADO_CARRERA: appConfig.API_ENDPOINT + '/gradoCarrera',
    ROUTE_GRADO_CARRERA_PUT: appConfig.API_ENDPOINT + '/gradoCarrera/info/estado',
    ROUTE_GRADO_CARRERA_MATERIA: appConfig.API_ENDPOINT + '/gradoCarreraMateria',
    ROUTE_GRADO_CARRERA_SECCION: appConfig.API_ENDPOINT + '/gradoCarreraSeccion',
    ROUTE_GRADO_CARRERA_SECCION_PUT: appConfig.API_ENDPOINT + '/gradoCarreraSeccion/info/estado',
    ROUTE_GRADO_CARRERA_SECCION_SERVICIO: appConfig.API_ENDPOINT + '/gradoCarreraSeccion/servicio',
    ROUTE_MATERIA: appConfig.API_ENDPOINT + '/materia',
    ROUTE_MATERIA_NOTA: appConfig.API_ENDPOINT + '/materiaNotas',
    ROUTE_BITACORA: appConfig.API_ENDPOINT + '/bitacora',
    ROUTE_FACTURA: appConfig.API_ENDPOINT + '/factura',
    ROUTE_PROMEDIO: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/info/promedio',
    ROUTE_INSCRITOS: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/info/inscritos',
    ROUTE_SERVICIOS_INSCRITOS: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/info/serviciosInscritos',
    ROUTE_XML_SIRE: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/info/xmlSire',
    ROUTE_DTE_PDF: appConfig.API_ENDPOINT + '/factura/pdf/dtesRango',
    //ASISTENCIA
    ROUTE_ASISTENCIA: appConfig.API_ENDPOINT + '/asistencia',
    ROUTE_ASISTENCIA_INFO: appConfig.API_ENDPOINT + '/asistencia/info',
    ROUTE_ASISTENCIA_HISTORIAL: appConfig.API_ENDPOINT + '/asistencia/historial',
    ROUTE_ASISTENCIA_HISTORIAL_MATERIA: appConfig.API_ENDPOINT + '/asistencia/historialMateria',
    ROUTE_ASISTENCIA_HISTORIAL_MATERIA_PENSUM: appConfig.API_ENDPOINT + '/asistencia/historialMateria/pensum',
    //CRUD USUARIO
    ROUTE_USUARIO: appConfig.API_ENDPOINT + '/usuario',
    ROUTE_USUARIO_MOODLE: appConfig.API_ENDPOINT + '/usuario/syncMoodle',
    ROUTE_RENICIO_PASS: appConfig.API_ENDPOINT + '/usuario/restart',
    ROUTE_UPDATE_PASS: appConfig.API_ENDPOINT + '/usuario/update',
    //CRUD ESTUDIANTE
    ROUTE_ESTUDIANTE: appConfig.API_ENDPOINT + '/estudiante',
    ROUTE_ESTUDIANTE_MOODLE: appConfig.API_ENDPOINT + '/estudiante/syncMoodle',
    ROUTE_ESTUDIANTECSV: appConfig.API_ENDPOINT + '/estudiante/csv',
    ROUTE_PERFIL_ESTUDIANTE: appConfig.API_ENDPOINT + '/perfilEstudiante',
    ROUTE_ABONOS_PENDIENTES: appConfig.API_ENDPOINT + '/abono/pendientes/deposito',
    ROUTE_ABONO_REPETIDO: appConfig.API_ENDPOINT + '/abono/boleta/existente',
    //CERTIFICADO NOTAS
    ROUTE_CERTIFICADO_UNIDADES: appConfig.API_ENDPOINT + '/estudiante/notas/filtro',
    ROUTE_CERTIFICADO_NOTAS: appConfig.API_ENDPOINT + '/estudiante/notas/reporte',
    ROUTE_CERTIFICADO_NOTAS_PDF: appConfig.API_ENDPOINT + '/estudiante/notas/generarPdf',
    ROUTE_CERTIFICADO_NOTAS_ACADEMICAS_PDF: appConfig.API_ENDPOINT + '/estudiante/carrerasAcademicas/certificado',
    ROUTE_CERTIFICADO_MATERIA_ACADEMICA_PDF: appConfig.API_ENDPOINT + '/materia/certificate',
    //CRUD OBSERVACION
    ROUTE_OBSERVACION: appConfig.API_ENDPOINT + '/observaciones',
    ROUTE_REPORTE_CARGOS: appConfig.API_ENDPOINT + '/contabilidad/cobros',
    ROUTE_REPORTE_ABONOS: appConfig.API_ENDPOINT + '/contabilidad/abonos',
    ROUTE_REPORTE_SALDOS: appConfig.API_ENDPOINT + '/contabilidad/saldos',
    ROUTE_REPORTE_BOLETAS_REPETIDAS: appConfig.API_ENDPOINT + '/abono/reporte/boletaRepetida',
    ROUTE_REPORTE_DTE: appConfig.API_ENDPOINT + '/factura',
    ROUTE_DTE_ANULACION: appConfig.API_ENDPOINT + '/factura/anular',
    ROUTE_NOTA_CREDITO: appConfig.API_ENDPOINT + '/notaCredito',
    ROUTE_REPORTE_DTE_PDF: appConfig.API_ENDPOINT + '/notaCredito',
    //SERVICIO ESTUDIANTE
    ROUTE_SERVICIO_ESTUDIANTE: appConfig.API_ENDPOINT + '/servicioEstudiante',
    ROUTE_HISTORIAL_MOVIMIENTOS: appConfig.API_ENDPOINT + '/estudiante/info/historialMovimientos',
    ROUTE_HISTORIAL_MOVIMIENTOS_PDF: appConfig.API_ENDPOINT + '/estudiante/info/historialMovimientosPDF',
    ROUTE_CAJA_ABONO_DETALLE: appConfig.API_ENDPOINT + '/abono/detalle',
    ROUTE_CAJA_CARGO: appConfig.API_ENDPOINT + '/cobro',
    ROUTE_CAJA_AJUSTE_CARGO: appConfig.API_ENDPOINT + '/ajusteCargo',
    ROUTE_COBROS_MENSUAL: appConfig.API_ENDPOINT + '/cobrosMensual',
    ROUTE_CAJA_ABONO: appConfig.API_ENDPOINT + '/abono',
    ROUTE_CAJA_AJUSTE_ABONO: appConfig.API_ENDPOINT + '/ajusteAbono',
    //GRUPO UNIDAD
    ROUTE_GRUPO_UNIDAD: appConfig.API_ENDPOINT + '/grupoUnidad',
    ROUTE_CONTROL_PROFESOR: appConfig.API_ENDPOINT + '/controlProfesor',
    ROUTE_PONDERACION: appConfig.API_ENDPOINT + '/ponderacion',
    ROUTE_PONDERACION_NOTAS: appConfig.API_ENDPOINT + '/controlProfesor/info/ponderacionNotas',
    ROUTE_CONTROL_PROFESOR_NOTAS: appConfig.API_ENDPOINT + '/controlProfesor/notas',
    ROUTE_CAMBIO_ROL: appConfig.API_ENDPOINT + '/rol/cambio',
    ROUTE_DASHBOARD_PROFESOR: appConfig.API_ENDPOINT + '/dashboard/profesor',
    ROUTE_HORARIO_CALENDARIO: appConfig.API_ENDPOINT + '/horario/calendario',
    ROUTE_HORARIO: appConfig.API_ENDPOINT + '/horario',
    ROUTE_VENTA: appConfig.API_ENDPOINT + '/venta',
    ROUTE_VENTA_UPD_NIT: appConfig.API_ENDPOINT + '/venta/actualizaNit',
    ROUTE_PRODUCTO: appConfig.API_ENDPOINT + '/producto',
    ROUTE_PERFILPADRE_NIT: appConfig.API_ENDPOINT + '/perfilpadre/atributo/nit',
    ROUTE_VALIDAR_NIT: appConfig.API_ENDPOINT + '/perfilpadre/info/nit',
    ROUTE_VALIDAR_CUI: appConfig.API_ENDPOINT + '/perfilpadre/info/cui',
    ROUTE_AVISO: appConfig.API_ENDPOINT + '/aviso',
    ROUTE_PAGO: appConfig.API_ENDPOINT + '/pago',
    ROUTE_EIQUETA_REGISTRO_CUENTA_CORRIENTE: appConfig.API_ENDPOINT + '/etiquetaRegistroCC',
    ROUTE_SINCRONIZAR_MOODLE: appConfig.API_ENDPOINT + '/moodle/sincronizar',
    ROUTE_SINCRONIZAR_MOODLE_ACADEMICA: appConfig.API_ENDPOINT + '/moodle/sincronizar/academia',
    ROUTE_GRUPO_APROBADOR: appConfig.API_ENDPOINT + '/grupoAprobador',
    ROUTE_GRUPO_APROBADOR_GCSC: appConfig.API_ENDPOINT + '/gradoCarreraSeccionCiclo/grupoAprobador',
    ROUTE_LISTA_SOLICITUD_APROBACION: appConfig.API_ENDPOINT + '/solicitudAprobacion/lista',
    ROUTE_DETALLE_SOLICITUD_APROBACION: appConfig.API_ENDPOINT + '/solicitudAprobacion/detalle',
    ROUTE_SEGUIMIENTO_SOLICITUD_APROBACION: appConfig.API_ENDPOINT + '/solicitudAprobacion/seguimiento',
    ROUTE_APROBACION_NOTA_REPORTE: appConfig.API_ENDPOINT + '/solicitudAprobacion/reporte',
    ROUTE_APROBACION_NOTA_REPORTE_FILTRO: appConfig.API_ENDPOINT + '/solicitudAprobacion/filtros',
    ROUTE_CERTIFICADOS: appConfig.API_ENDPOINT + '/estudiante/notas/grado',
    //SERVICIO CONTROL ACADEMICO
    ROUTE_CARRERA_ACADEMICA: appConfig.API_ENDPOINT + '/gradoCarrera',
    ROUTE_CICLO_ACADEMICO: appConfig.API_ENDPOINT + '/ciclo',
    ROUTE_CICLO_ESTUDIANTES: appConfig.API_ENDPOINT + '/ciclo/estudiantes',
    ROUTE_INSCRIPCION_ACADEMICA: appConfig.API_ENDPOINT + '/pensum/inscripcionCarrera',
    ROUTE_CONFIG_INFO_CICLO: appConfig.API_ENDPOINT + '/cicloSeccionMateria/info/create',
    ROUTE_CONFIG_CICLO: appConfig.API_ENDPOINT + '/cicloSeccionMateria',
    ROUTE_CONFIG_CICLO_PROFESOR: appConfig.API_ENDPOINT + '/cicloSeccionMateria/profesor',
    ROUTE_CARRERA_MATERIA_INFO_ACADEMICA: appConfig.API_ENDPOINT + '/carreraAcademicaMateria/info/create',
    ROUTE_CARRERA_MATERIA_ACADEMICA: appConfig.API_ENDPOINT + '/carreraAcademicaMateria',
    ROUTE_CARRERA_MATERIA_PREREQ_ACADEMICA: appConfig.API_ENDPOINT + '/carreraAcademicaMateria/prerrequisitos',
    ROUTE_MATRICULA_ACADEMICA_INFO: appConfig.API_ENDPOINT + '/matriculaCicloEstudiante/info/create',
    ROUTE_ESTUDIANTE_CARRERA_ACADEMICA: appConfig.API_ENDPOINT + '/estudiante/info/carrerasAcademicas',
    ROUTE_MATRICULA_ACADEMICA_MATERIA_INFO: appConfig.API_ENDPOINT + '/matriculaCicloEstudiante/info/materias',
    ROUTE_ASISTENCIA_ACADEMICA: appConfig.API_ENDPOINT + '/asistencia/pensum',
    ROUTE_MATRICULA_ACADEMICA_MATERIA_ASIG_INFO: appConfig.API_ENDPOINT + '/matriculaCicloEstudiante/info/materiasAsignadas',
    ROUTE_MATRICULA_ACADEMICA: appConfig.API_ENDPOINT + '/matriculaCicloEstudiante',
    ROUTE_MATRICULA_ESTUDIANTE: appConfig.API_ENDPOINT + '/ciclo/estudiantes',
    ROUTE_MATRICULA_ACADEMICA_MATERIA: appConfig.API_ENDPOINT + '/matriculaCicloEstudiante/materiaSeccionEstudiante',
    ROUTE_PONDERACION_ACADEMICA: appConfig.API_ENDPOINT + '/ponderacionAcademica',
    ROUTE_CONTROL_NOTAS_ACADEMICO: appConfig.API_ENDPOINT + '/controlNotasAcademico',
    ROUTE_SOLICITUD_APROBACION_ACADEMICO: appConfig.API_ENDPOINT + '/solicitudAprobacion/seguimientoAcademico',
    ROUTE_SINCRONIZAR_NOTA_MOODLE: appConfig.API_ENDPOINT + '/moodle/sync/notas',
    ROUTE_LOGO:appConfig.API_ENDPOINT + '/login',
}
